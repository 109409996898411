import { Logos } from "../constants/logos";
import { navLinks } from "../constants/navLinks";

export default function Navbar() {
  return (
    <nav className="flex justify-between py-5 xl:px-32 md:px-16 xs:px-8 px-4 bg-purplec-500 z-50 relative">
      <div className="flex md:py-3 py-2">
        <img src={Logos.H} alt="logo" className="h-8" />
        <img src={Logos.R} alt="logo" className="h-8" />
      </div>
      <div className="flex justify-end items-center">
      <div className="flex md:gap-12 gap-8 bg-bluev-500 md:py-3 py-2 rounded-full">
          {navLinks.map((link, index) => (
            <a href={link.path} key={index}>
              {link.title}
            </a>
          ))}
        </div>
      </div>
    </nav>
  );
}
