import CodeHive from "../assests/images/projects/codehive.png"
import Shipfro from "../assests/images/projects/shipfro.png"
import Astros from "../assests/images/projects/astros.gif"
import Workaholics from "../assests/images/projects/workaholics.png"

const Images = {
    CodeHive,
    Shipfro,
    Astros,
    Workaholics
};

export default Images;
