export const navLinks = [
    {
        title: 'Projects',
        path: '/#projects'
    },
    {
        title: 'Work',
        path: '/#work'
    },
    {
        title: 'Contact',
        path: '/#contact'
    }
]