import React from "react";
import { socials } from "../constants/socials";

export default function HeroSection() {
  return (
    <div className="bg-greenn-900 h-[calc(100vh-88px)] xl:px-32 md:px-16 xs:px-8 px-4 md:pt-10 pb-10 pt-0 flex flex-col md:gap-32 gap-16 justify-center relative">
      <div
        className="xl:h-72 xl:w-72 h-44 w-44 bg-yellow-500 rounded-full absolute lg:left-[30vw] xl:top-[60vh] lg:top-[70vh] md:top-[60vh] md:left-[5vw]
        xs:left-[65vw] top-[65vh]
        left-[52vw]
      "
      ></div>
      <div
        className="xl:h-24 xl:w-24 lg:h-16 lg:w-16 md:w-10 md:h-10 bg-purple-500 rounded-full absolute lg:left-[10vw] lg:top-[50vh] 
      xs:top-[10vh] md:left-[80vw]
      xs:left-[87vw] w-16 h-16
      left-[80vw] top-[5vw]
      "
      ></div>
      <div className="xl:h-44 xl:w-44 h-36 w-36 bg-red-500 rounded-full absolute top-32 lg:right-[25vh] right-[10vw] lg:block hidden"></div>
      <div className="xl:h-16 xl:w-16 w-8 h-8 bg-green-500 rounded-full absolute left-[41vw] xs:top-[2vh] top-[0]"></div>
      <div className="relative">
        <div className="lg:text-[1.5rem] xs:text-[1.25rem] font-light relative z-10">
          Welcome, I'm
        </div>
        <div className="lg:text-[7rem] md:text-[5rem] xs:text-[4rem] text-[3.25rem] leading-[3.4rem] font-semibold lg:leading-[6rem] md:leading-[5rem] xs:leading-[4rem] uppercase relative z-10 hero">
          Hamna Rauf
        </div>
        <div className="lg:text-[1.5rem] xs:text-[1.25rem] font-light relative z-10">
          A Software Developer
        </div>
      </div>
      <div className="md:self-end relative z-10">
        <div className="max-w-[500px]">
          Developing software, predominantly in the space of web development.
          I love bringing ideas to life through code and design. Always on the
          lookout for the latest tools and techonologies to enhance my craft.
        </div>
        <div className="flex gap-4 pt-5">
          {socials.map((social, index) => (
            <a key={index} href={social.link} target="_blank" rel="noreferrer">
              <div className="w-[50px] h-[50px] p-4 bg-theme-secondary rounded-full">
                <img src={social.icon} alt={social.name} />
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
