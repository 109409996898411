import { useEffect } from "react";
import { getRandomTextColor } from "../utils/colors";
import { useRef } from "react";

export default function Footer() {
  const ref = useRef(null);
  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const observer = new IntersectionObserver(
      async ([entry]) => {
        if (entry.isIntersecting) {
          const hollowLetters = document.querySelectorAll(".hollow-letter");
          for (const letter of hollowLetters) {
            onHover(letter);
            setTimeout(() => {
              onMouseLeave(letter);
            }, 100);
            await wait(40);
          }
        }
      },
      {
        threshold: 0.5,
      }
    );

    const current = ref.current;
    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, []);


  function removeColor(element) {
    const classesToRemove = ["transition-transparent", "before:opacity-[0.15]"];

    element.classList.forEach((className) => {
      if (className.startsWith("text-") && className.endsWith("400")) {
        classesToRemove.push(className);
      }
      if (className.startsWith("before:bg-") && className.endsWith("400")) {
        classesToRemove.push(className);
      }
    });

    classesToRemove.forEach((className) => {
      element.classList.remove(className);
    });
  }

  function onHover(element) {
    removeColor(element);

    element.classList.remove("text-transparent");
    element.classList.remove("before:bg-transparent");

    const { text, bg } = getRandomTextColor();
    element.classList.add(text, bg, "before:opacity-[0.15]");
  }

  function onMouseLeave(element) {
    removeColor(element);
    element.classList.add("transition-transparent");
  }

  const callToActionText = [
    "L",
    "E",
    "T",
    "'",
    "S",
    "&nbsp;",
    "C",
    "R",
    "E",
    "A",
    "T",
    "E",
    "<br/>",
    "S",
    "O",
    "M",
    "E",
    "T",
    "H",
    "I",
    "N",
    "G",
    "<br/>",
    "R",
    "E",
    "M",
    "A",
    "R",
    "K",
    "A",
    "B",
    "L",
    "E",
  ];

  return (
    <footer
      className="md:h-[100vh] h-[80vh] md:pt-28 pt-16 flex flex-col items-center relative overflow-hidden"
      id="contact"
    >
      <div
        className="
        uppercase text-[7rem] text-center leading-[7rem] max-w-[900px] max-[888px]:text-[5rem] max-[888px]:leading-[5.25rem]  
      max-[600px]:text-[4rem] max-[600px]:leading-[4.5rem] max-[600px]:font-bold
      max-[600px]:tracking-tight max-[450px]:text-[3.75rem] 
      max-[400px]:text-[3.5rem] 
      font-semibold
      "
        ref={ref}
      >
        <div className="text-transparent hollow">
          {callToActionText.map((letter, index) => (
            <div
              key={index}
              className={`hollow-letter inline before:blur-md z-20`}
              onMouseEnter={(e) => onHover(e.target)}
              onMouseLeave={(e) => onMouseLeave(e.target)}
              dangerouslySetInnerHTML={{
                __html: letter === " " ? "&nbsp;" : letter,
              }}
            ></div>
          ))}
        </div>
      </div>
      <div className="flex gap-3 relative mt-12 text-center min-[600px]:flex-row flex-col">
        <a
          href="mailto:hamnarauf7@gmail.com"
          className="
          bg-theme-tertiary py-3 px-6 rounded-full 
          w-[272px]
          text-center font-[500] tracking-tighter hover:underline"
          target="_blank"
        >
          hamnarauf7@gmail.com
        </a>
        <a
          href="https://www.linkedin.com/in/hamnarauf/"
          className="bg-theme-tertiary py-3 px-6 rounded-full w-[272px] text-center font-[500] tracking-tighter hover:underline"
          target="_blank"
        >
          Linkedin @hamnarauf
        </a>
      </div>
      <div className="tracking-[8px] flex mt-auto pb-3 text-center">
        Happiness
        <span className="text-red-400 sm:ml-4 ml-2">
          <span className="text-red-500">G</span>
          <span className="text-green-500">u</span>
          <span className="text-blue-500">a</span>
          <span className="text-purple-500">r</span>
          <span className="text-orange-500">a</span>
          <span className="text-teal-500">n</span>
          <span className="text-pink-500">t</span>
          <span className="text-amber-500">e</span>
          <span className="text-indigo-500">e</span>
          <span className="text-red-500">d</span>
        </span>
      </div>
    </footer>
  );
}
