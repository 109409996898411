import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { work } from "../constants/work";
import {
  faArrowDown,
  faArrowUpRightFromSquare,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { parseTextWithLinks } from "../utils/parser";

export default function Work() {
  function openAccordion(e) {
    const element = e.target;
    const accordion = element.closest(".accordion-item");
    const content = accordion.querySelector(".accordion-content");
    const chevron = accordion.querySelector(".chevron"); 

    if (content.style.maxHeight === "0px" || content.style.maxHeight === "") {
      content.style.maxHeight = "100vh";
      chevron.style.transform = "rotate(-180deg)";
    } else {
      content.style.maxHeight = "0px";
      chevron.style.transform = "rotate(0deg)";
    }
  }


  return (
    <div
      className="xl:px-32 md:px-16 xs:px-8 px-4 sm:py-10 pt-10 pb-0 lg:mb-12 sm:min-h-[100vh] min-h-[80vh] relative bg-cyaffn-200 overflow-hidden"
      id="work"
    >
      <div className="lg:h-52 lg:w-52 h-32 w-32 bg-rose-500 rounded-full absolute lg:right-[5vw] lg:top-[65vh] md:right-[2vw] hidden  md:block"></div>
      <div className="h-16 w-16 bg-teal-500 rounded-full absolute lg:right-[20vw] lg:top-[40vh] right-[4vw] top-[80vh] hidden md:block"></div>
      <div className="h-24 w-24 bg-indigo-500 rounded-full absolute lg:right-[5vw] lg:top-[10vh] -right-[4vw] top-[45vh] hidden md:block"></div>

      <div className="mb-8">
        <div className="uppercase text-[4rem] leading-none tracking-[2px] pt-10">
          WORK
        </div>
        <div className="text-theme-secondary-foreground mt-2">
          These are the organizations I've worked with
        </div>
      </div>

      <div className="lg:w-[55vw] md:w-[75vw] w-full bg-yellouw-300 pt-10">
        <div className="flex flex-col gap-10">
          {work?.map((job, index) => (
            <div
              className="accordion-item border-b-[1px] border-theme-tertiary"
              key={index}
            >
              <div
                className="accordion-header flex justify-between pb-4 cursor-pointer"
                onClick={openAccordion}
              >
                <div>
                  <div className="md:text-[1.2rem] xxs:text-[1rem] text-[15px] font-medium">
                    {job.title}
                  </div>
                  <div className="flex items-center xxs:gap-2 gap-1 font-light mt-1 md:text-[1rem] xxs:text-[14px] text-[13px]">
                    <div>{job.company}</div>
                    <div className="min-w-[6px] min-h-[6px] bg-theme-secondary-foreground rounded-full"></div>
                    <div>{job.type}</div>
                  </div>
                </div>
                <div className="flex items-center md:gap-2 gap-1 md:text-[1rem] xxs:text-[14px] text-[13px]">
                  <div>{job.start}</div> - <div>{job.end}</div>
                  <FontAwesomeIcon icon={faChevronDown} className="sm:ml-2 ml-1 chevron" />
                </div>
              </div>

              <div className="accordion-content bg-pinky-200">
                <div className="pb-8 pt-4 flex flex-col gap-2">
                  {job.tasks?.map((responsibility, index) => (
                    <div
                      className="flex md:gap-4 sm:gap-2 gap-1 items-center"
                      key={index}
                    >
                      <div className="font-grotesk text-[2rem] w-[60px] bg-purplre-100 max-w-[50px] min-w-[50px]">
                        0{index + 1}
                      </div>
                      <div
                        className="md:text-[15px] text-[14px] work-responsibility"
                      >
                        {parseTextWithLinks(responsibility)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
