export const backgroundColors = [
    "bg-red-500",
    "bg-green-500",
    "bg-blue-500",
    "bg-purple-500",
    "bg-orange-500",
    "bg-teal-500",
    "bg-pink-500",
    "bg-yellow-500",
    "bg-indigo-500",
    "bg-lime-500",
    "bg-cyan-500",
    "bg-amber-500",
    "bg-emerald-500",
    "bg-sky-500",
    "bg-violet-500",
    "bg-fuchsia-500",
    "bg-rose-500",
]

export const TextColors = [
    {
        text: "text-red-400",
        bg: "before:bg-red-400"
    },
    {
        text: "text-green-400",
        bg: "before:bg-green-400"
    },
    {
        text: "text-blue-400",
        bg: "before:bg-blue-400"
    },
    {
        text: "text-purple-400",
        bg: "before:bg-purple-400"
    },
    {
        text: "text-orange-400",
        bg: "before:bg-orange-400"
    },
    {
        text: "text-teal-400",
        bg: "before:bg-teal-400"
    },
    {
        text: "text-pink-400",
        bg: "before:bg-pink-400"
    },
    {
        text: "text-yellow-400",
        bg: "before:bg-yellow-400"
    },
    {
        text: "text-indigo-400",
        bg: "before:bg-indigo-400"
    },
    {
        text: "text-lime-400",
        bg: "before:bg-lime-400"
    },
    {
        text: "text-cyan-400",
        bg: "before:bg-cyan-400"
    },
    {
        text: "text-amber-400",
        bg: "before:bg-amber-400"
    },
    {
        text: "text-emerald-400",
        bg: "before:bg-emerald-400"
    },
    {
        text: "text-sky-400",
        bg: "before:bg-sky-400"
    },
    {
        text: "text-violet-400",
        bg: "before:bg-violet-400"
    },
    {
        text: "text-fuchsia-400",
        bg: "before:bg-fuchsia-400"
    },
    {
        text: "text-rose-400",
        bg: "before:bg-rose-400"
    },
]