import { Logos } from "./logos";

export const socials = [
    {
        name: "GitHub",
        link: "https://github.com/hamnarauf",
        icon: Logos.Github,
    },
    {
        name: "LinkedIn",
        link: "https://linkedin.com/in/hamnarauf/",
        icon: Logos.Linkedin,
    },
    {
        name: "Mail",
        link: "mailto:hamnarauf7@gmail.com",
        icon: Logos.Mail,
    },
]