export const work = [
    {
        title: "Team Lead (Front-end)",
        company: "Asteramind",
        start: "Dec 23",
        end: "Present",
        type: "Part-time",
        tasks: [
            "Lead a team of three developers, orchestrating task assignments, conducting daily one-to-one meetings and providing guidance.",
            "Translate Figma designs into responsive web pages with pixel-perfect precision.",
            "Implement timely solutions for client’s requirements (new features and bugs), following agile development methodology.",
            "Conduct code reviews and provide actionable feedback to consistently deliver high-quality and efficient code."
        ],
        link: "shipfro.com",
    },
    {
        title: "Software Design Engineer",
        company: "xFlow Research Inc.",
        start: "Jun 22",
        end: "July 23",
        type: "Part-time",
        tasks: [
            "Contribute to the development of Linux Foundation [SONiC](https://sonicfoundation.dev), a network operating system.",
            "Authored a [design spec](https://github.com/sonic-net/SONiC/pull/1298) (4000+ words) for Multiple Spanning Tree Protocol in SONiC consisting of architecture, sequence diagrams, UX design, database schema & test plan.",
            "Presented & successfully defended the design to 40+ SONiC community members consisting of reviewers from Microsoft, Nvidia, Google, Broadcom, and others. [Link to recording](https://zoom.us/rec/share/3XxqJ20eag07iK0x7T_4YqBq6jhbyWXGjpk72AvHL35peWSOXZ04w_ZQjGlwXJbM.0DPyXu03R_bS0Gry)",
            "Comprehend the SONiC implementation of network protocols by studying 10+ GitHub repositories.",
        ],

    },
   {
    title: "Research Assistant",
    company: "HPC Lab, NUST",
    start: "Jun 22",
    end: "Nov 22",
    type: "Internship",
    tasks: [
        "Conducted research on Electric Vehicles for the development of sustainable transportation solutions and developed a framework to study the EVs adoption over infrastructure.",
        "Designed an algorithm for optimization of EV charging reservations over the provided infrastructure.",
        "Simulated using AnyLogic to evaluate the proposed solution and demonstrate its effectiveness.",
        "Publication: [UrbanEVSim: Open-Source Electric Vehicle Mobility and Charging Simulation Platform](https://ieeexplore.ieee.org/document/10410367)"
    ],
   }
]