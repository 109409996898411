import CSS from '../assests/images/Logos/technologies/css.svg';
import HTML from '../assests/images/Logos/technologies/html.svg';
import JS from '../assests/images/Logos/technologies/javascript.svg';
import React from '../assests/images/Logos/technologies/react.svg';
// import Node from '../assests/images/Logos/technologies/node.svg';
import MongoDB from '../assests/images/Logos/technologies/mongodb.svg';
// import docker, nextjs, tailwindcss, typescript, terraform, vitest, cypress
import Docker from '../assests/images/Logos/technologies/docker.svg';
import NextJS from '../assests/images/Logos/technologies/nextjs.svg';
import TailwindCSS from '../assests/images/Logos/technologies/tailwind.svg';
import TypeScript from '../assests/images/Logos/technologies/typescript.svg';
import Terraform from '../assests/images/Logos/technologies/terraform.svg';
// import Vitest from '../assests/images/Logos/technologies/vitest.svg';
import Cypress from '../assests/images/Logos/technologies/cypress.svg';
import MUI from '../assests/images/Logos/technologies/mui.svg';
import Python from '../assests/images/Logos/technologies/python.svg';
import Pygame from  '../assests/images/Logos/technologies/pygame.svg';
import Php from '../assests/images/Logos/technologies/php.svg';
import Laravel from '../assests/images/Logos/technologies/laravel.svg';
import MySQL from '../assests/images/Logos/technologies/mysql.svg';

export const Logos = {
    css: CSS,
    html: HTML,
    javascript: JS,
    react: React,
    mongodb: MongoDB,
    docker: Docker,
    nextjs: NextJS,
    tailwindcss: TailwindCSS,
    typescript: TypeScript,
    terraform: Terraform,
    cypress: Cypress,
    mui: MUI,
    python: Python,
    pygame: Pygame,
    php: Php,
    laravel: Laravel,
    mysql: MySQL,
}