import Footer from './components/Footer';
import HeroSection from './components/HeroSection';
import Navbar from './components/Navbar';
import Projects from './components/Projects';
import Work from './components/Work';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";


gsap.registerPlugin(useGSAP, ScrollTrigger, ScrollToPlugin);

function App() {
  return (
    <div className='bg-theme-primary text-theme-secondary font-poppins'>
      <Navbar />
      <HeroSection />
      <Projects />
      <Work />
      <Footer />
    </div>

  );
}

export default App;
