import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function parseTextWithLinks(textToConvert, className="text-rose-500") {
    const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(textToConvert)) !== null) {
      const text = match[1];
      const url = match[2];

      if (match.index > lastIndex) {
        parts.push(textToConvert.substring(lastIndex, match.index));
      }

      parts.push(
        <a href={url} target="_blank" rel="noopener noreferrer" key={text} >
          <span className={`underline ${className}`}>
            {text}
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              className="ml-1 h-3 mb-[2px]"
            />
          </span>
        </a>
      );
      lastIndex = regex.lastIndex;
    }

    if (lastIndex < textToConvert.length) {
      parts.push(textToConvert.substring(lastIndex));
    }

    return parts;
  }